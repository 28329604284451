<template>
	<div>
		<header class="flex-shrink-0 lg:sticky lg:top-0 z-10 bg-[#f5f6fc] py-5 px-4 2xl:px-6 dark:bg-[#111827]">
			<div class="flex items-center justify-between">
				<!-- Navbar left -->
				<div class="flex items-center space-x-3 md:w-[38%]">
					<!-- Toggle sidebar button -->
					<button @click="toggleSidebar()" class="p-2 rounded-md focus:outline-none "
						:class="{ 'lg:hidden  ': sidebarOpen }">
						<i class="fa-regular fa-bars-sort text-[#42B8FE]"></i>
					</button>
					<form class="items-center w-full hidden md:flex">
						<label for="simple-search" class="sr-only">Search</label>
						<div class="relative w-full">
							<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
								<svg xmlns="http://www.w3.org/2000/svg" class="w-[18px] h-[18px]" width="20" height="20"
									viewBox="0 0 20 20" fill="none">
									<path
										d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
										stroke="#42B8FE" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</div>
							<input type="text" id="simple-search"
								class="bg-white border border-white text-gray-900 rounded-md focus:ring-[#42B8FE] focus:border-[#42B8FE] block w-full pl-14 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#42B8FE] h-[46px] dark:focus:border-[#42B8FE] placeholder:text-[#727B9D]"
								placeholder="Search" required />
						</div>
					</form>
				</div>
				<!-- Navbar right -->
				<div class="flexBetween w-max gap-3 2xl:gap-6">
					<div class="hidden">
						<a href="#">
							<button
								class="middle none text-[#727B9D] center rounded-lg py-3 px-6 font-medium transition-all hover:bg-gray-100 active:bg-[#B362E5]/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none dark:hover:bg-gray-700 dark:hover:text-white"
								data-ripple-dark="true">
								Upgrade Plan
							</button>
						</a>
					</div>
					<div class="relative">
						<button @click="toggleTheme" id="theme-toggle" type="button"
							class="middle none text-[#727B9D] center rounded-lg p-2.5 font-bold transition-all hover:bg-gray-100 active:bg-[#B362E5]/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none dark:hover:bg-gray-700 dark:hover:text-white"
							data-ripple-dark="true">
							<svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
								viewBox="0 0 20 20" fill="none" :class="[isDarkMode ? 'hidden' : '']">
								<path
									d="M18.9618 10.79C18.8045 12.4922 18.1657 14.1144 17.1201 15.4668C16.0744 16.8192 14.6653 17.8458 13.0575 18.4265C11.4497 19.0073 9.7098 19.1181 8.04132 18.7461C6.37283 18.3741 4.84481 17.5345 3.63604 16.3258C2.42727 15.117 1.58775 13.589 1.21572 11.9205C0.843691 10.252 0.954531 8.51208 1.53528 6.9043C2.11602 5.29651 3.14265 3.88737 4.49503 2.84175C5.84741 1.79614 7.46961 1.15731 9.17182 1C8.17523 2.34827 7.69566 4.00945 7.82035 5.68141C7.94503 7.35338 8.66568 8.92506 9.85122 10.1106C11.0368 11.2961 12.6084 12.0168 14.2804 12.1415C15.9524 12.2662 17.6135 11.7866 18.9618 10.79Z"
									stroke="#42B8FE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							<svg id="theme-toggle-light-icon" :class="['w-5 h-5', isDarkMode ? '' : 'hidden']"
								fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
									fill-rule="evenodd" clip-rule="evenodd"></path>
							</svg>
						</button>
					</div>

					<!-- Notification Dropdown -->
					<NotificationsDropdown v-if="showNavbar" />

					<!-- Profile Dropdown -->
					<NavbarProfileDropdown :user="user_detail?.data?.user" @profile-updated="refreshUser" />
				</div>
			</div>
		</header>

		<div class="p-4 sm:p-8" v-if="showNavbar">
			<!-- Update Notification Banner -->
			<div
				v-if="new Date(user_detail?.data?.user?.creation) <= new Date('2025-03-13')"
				class="mb-4 bg-blue-50 py-6 px-7 border border-blue-200 rounded-lg flex justify-between items-center dark:bg-blue-900 dark:border-blue-700">
				<div class="flex items-center gap-3">
					<!-- Information Icon -->
					<svg class="w-6 h-6 text-blue-500 dark:text-blue-300" xmlns="http://www.w3.org/2000/svg" fill="none"
						viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							d="M13 16h-1v-4h-1m1-4h.01M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
					</svg>
					<div>
						<h1 class="text-[#181F3A] font-medium text-sm dark:text-white">🚀 Exciting Upgrade!</h1>
						<p class="text-sm font-medium text-blue-700 dark:text-blue-300 mt-1">
							We've enhanced Rebuid.ai with powerful new AI features! To give you a fresh start, we’ve
							reset
							everything and fully reissued your used credits. Enjoy the upgrade and start creating! 🎉
						</p>
					</div>
				</div>
			</div>
			<!-- Welcome Section   -->
			<div
				class="flex justify-between [@media(min-width:1400px)]:items-center bg-white dark:bg-gray-800 py-6 px-7 rounded-[10px] gap-x-4 gap-y-6 flex-col [@media(min-width:1400px)]:flex-row">
				<div class="flex justify-start items-center gap-4 flex-1 [@media(min-width:1400px)]:w-auto w-max">
					<img loading="lazy"
						:src="user_detail?.data?.user?.user_image ? user_detail?.data?.user?.user_image : `https://ui-avatars.com/api/?name=${user_detail?.data?.user?.full_name}`"
						class="w-14 h-14 rounded-full object-cover" alt="" />
					<div class="">
						<h1 class="text-[#181F3A] md:text-base lg:text-lg font-semibold dark:text-white">
							<span id="morning_greeting" :class="{ hidden: currentGreeting != 'morning' }">Good
								Morning,</span>
							<span id="afternoon_greeting" :class="{ hidden: currentGreeting != 'aftermoon' }">Good
								Afternoon,</span>
							<span id="evening_greeting" :class="{ hidden: currentGreeting != 'evening' }">Good
								Evening,</span>
							{{ user_detail.data?.user?.full_name }}!
						</h1>
						<p class="text-[#727B9D] text-sm font-medium">Here is what’s new!</p>
					</div>
				</div>
				<div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-4">
					<div class="py-3 px-4 sm:border-r border-[#E5E5E5] bg-white rounded-lg sm:!shadow-none sm:rounded-none sm:py-2 dark:bg-gray-800 sm:dark:bg-transparent dark:border-gray-700"
						style="box-shadow: 0px 36px 60px rgba(128, 131, 157, 0.12)">
						<div class="flex justify-start items-start xl:items-center gap-3 xl:flex-row md:flex-col">
							<svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" width="46" height="46"
								viewBox="0 0 46 46" fill="none">
								<rect width="46" height="46" rx="10" fill="url(#paint0_linear_2_198)" />
								<path
									d="M29 14C28.2044 14 27.4413 14.3161 26.8787 14.8787C26.3161 15.4413 26 16.2044 26 17V29C26 29.7956 26.3161 30.5587 26.8787 31.1213C27.4413 31.6839 28.2044 32 29 32C29.7956 32 30.5587 31.6839 31.1213 31.1213C31.6839 30.5587 32 29.7956 32 29C32 28.2044 31.6839 27.4413 31.1213 26.8787C30.5587 26.3161 29.7956 26 29 26H17C16.2044 26 15.4413 26.3161 14.8787 26.8787C14.3161 27.4413 14 28.2044 14 29C14 29.7956 14.3161 30.5587 14.8787 31.1213C15.4413 31.6839 16.2044 32 17 32C17.7956 32 18.5587 31.6839 19.1213 31.1213C19.6839 30.5587 20 29.7956 20 29V17C20 16.2044 19.6839 15.4413 19.1213 14.8787C18.5587 14.3161 17.7956 14 17 14C16.2044 14 15.4413 14.3161 14.8787 14.8787C14.3161 15.4413 14 16.2044 14 17C14 17.7956 14.3161 18.5587 14.8787 19.1213C15.4413 19.6839 16.2044 20 17 20H29C29.7956 20 30.5587 19.6839 31.1213 19.1213C31.6839 18.5587 32 17.7956 32 17C32 16.2044 31.6839 15.4413 31.1213 14.8787C30.5587 14.3161 29.7956 14 29 14Z"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<defs>
									<linearGradient id="paint0_linear_2_198" x1="30.5963" y1="-46.902" x2="-38.1526"
										y2="-0.99382" gradientUnits="userSpaceOnUse">
										<stop stop-color="#09EAFE" />
										<stop offset="1" stop-color="#42B8FE" />
									</linearGradient>
								</defs>controller
							</svg>
							<div>
								<h3 class="text-[#181F3A] font-medium dark:text-white text-sm 2xl:text-base">
									Generated Products
								</h3>
								<div class="flex justify-start items-center gap-2">
									<p
										class="font-semibold bg-gradient-to-r from-[#42B8FE] via-[#24D2FE] to-[#09EAFE] inline-block text-transparent bg-clip-text">
										{{ userLimit?.data?.products_used }}
									</p>
									<p class="text-[#727B9D] text-sm font-medium">
										(out of {{ userLimit?.data?.product_limit > 100000 ? '∞' :
											userLimit?.data?.product_limit }})
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="py-3 px-4 md:border-r border-[#E5E5E5] bg-white rounded-lg sm:!shadow-none sm:rounded-none sm:py-2 dark:bg-gray-800 sm:dark:bg-transparent dark:border-gray-700"
						style="box-shadow: 0px 36px 60px rgba(128, 131, 157, 0.12)">
						<div class="flex justify-start items-start xl:items-center gap-3 xl:flex-row md:flex-col">
							<svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" width="46" height="46"
								viewBox="0 0 46 46" fill="none">
								<rect width="46" height="46" rx="10" fill="url(#paint0_linear_2_206)" />
								<path
									d="M32 16C32 17.6569 27.9706 19 23 19C18.0294 19 14 17.6569 14 16M32 16C32 14.3431 27.9706 13 23 13C18.0294 13 14 14.3431 14 16M32 16V30C32 31.66 28 33 23 33C18 33 14 31.66 14 30V16M32 23C32 24.66 28 26 23 26C18 26 14 24.66 14 23"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<defs>
									<linearGradient id="paint0_linear_2_206" x1="30.5963" y1="-46.902" x2="-38.1526"
										y2="-0.99382" gradientUnits="userSpaceOnUse">
										<stop stop-color="#09EAFE" />
										<stop offset="1" stop-color="#42B8FE" />
									</linearGradient>
								</defs>
							</svg>
							<div>
								<h3 class="text-[#181F3A] font-medium dark:text-white text-sm 2xl:text-base">
									Generated Images
								</h3>
								<div class="flex justify-start items-center gap-2">
									<p
										class="font-semibold bg-gradient-to-r from-[#42B8FE] via-[#24D2FE] to-[#09EAFE] inline-block text-transparent bg-clip-text">
										{{ userLimit?.data?.images_generated }}
									</p>
									<p class="text-[#727B9D] text-sm font-medium">
										(out of {{ userLimit?.data?.shopify_shop_limit > 100000 ? '∞' :
											userLimit?.data?.image_generation_limit }})
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="py-3 px-4 bg-white rounded-lg sm:!shadow-none sm:rounded-none sm:py-2 dark:bg-gray-800 sm:dark:bg-transparent dark:border-gray-700"
						style="box-shadow: 0px 36px 60px rgba(128, 131, 157, 0.12)">
						<div class="flex justify-start items-start xl:items-center gap-3 xl:flex-row md:flex-col">
							<svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" width="46" height="46"
								viewBox="0 0 46 46" fill="none">
								<rect width="46" height="46" rx="10" fill="url(#paint0_linear_2_216)" />
								<path
									d="M14 17L17 13H29L32 17M14 17V31C14 31.5304 14.2107 32.0391 14.5858 32.4142C14.9609 32.7893 15.4696 33 16 33H30C30.5304 33 31.0391 32.7893 31.4142 32.4142C31.7893 32.0391 32 31.5304 32 31V17M14 17H32M27 21C27 22.0609 26.5786 23.0783 25.8284 23.8284C25.0783 24.5786 24.0609 25 23 25C21.9391 25 20.9217 24.5786 20.1716 23.8284C19.4214 23.0783 19 22.0609 19 21"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<defs>
									<linearGradient id="paint0_linear_2_216" x1="30.5963" y1="-46.902" x2="-38.1526"
										y2="-0.99382" gradientUnits="userSpaceOnUse">
										<stop stop-color="#09EAFE" />
										<stop offset="1" stop-color="#42B8FE" />
									</linearGradient>
								</defs>
							</svg>
							<div>
								<h3 class="text-[#181F3A] font-medium dark:text-white text-sm 2xl:text-base">
									Shopify Stores
								</h3>
								<div class="flex justify-start items-center gap-2">
									<p
										class="font-semibold bg-gradient-to-r from-[#42B8FE] via-[#24D2FE] to-[#09EAFE] inline-block text-transparent bg-clip-text">
										{{ user_detail.data?.connected_shopify_stores }}
									</p>
									<p class="text-[#727B9D] text-sm font-medium">(out of {{
										userLimit?.data?.shopify_shop_limit > 100000 ? '∞' :
											userLimit?.data?.shopify_shop_limit }})</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Welcome Section  -->
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { sidebarStore } from '../../store/sidebarStore'
import { createResource } from 'frappe-ui'
import NavbarProfileDropdown from './NavbarProfileDropdown.vue'
import NotificationsDropdown from './NotificationsDropdown.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router';


export default {
	name: 'DashboardNavbar',
	setup() {
		const user_detail = createResource({
			url: 'rebuid.rebuid.api.get_user_details',
			auto: true,
		})

		const userLimit = createResource({
			url: 'rebuid.rebuid.api.check_user_limits',
			auto: true,
		})

		const store = sidebarStore()
		const { toggleSidebar } = store
		const { isSidebarOpen } = storeToRefs(store)
		const sidebarOpen = isSidebarOpen

		const refreshUser = () => {
			user_detail.fetch()
		}
		const route = useRoute();

		// Define a computed property to check if the navbar should be shown
		const showNavbar = computed(() => {
			console.log(route)
			return ['dashboard', 'product-generation', 'ai-products', 'shops', 'ai-templates'].includes(route.name);
		});
		// const shouldShowNotification = computed(() => {
		// 	console.log(user_detail?.data?.user?.creation);
		// 	if (!user_detail?.data?.user?.creation) return false;

		// 	const userCreationDate = new Date(user_detail.value.data.user.creation);
		// 	const cutoffDate = new Date('2025-03-13'); // March 13, 2025
		// 	console.log(userCreationDate <= cutoffDate,cutoffDate,userCreationDate )
		// 	return new Date(user_detail.value.data.user.creation) <= new Date('2025-03-13');
		// });



		return { user_detail, toggleSidebar, sidebarOpen, refreshUser, userLimit, showNavbar }
	},
	components: {
		NavbarProfileDropdown,
		NotificationsDropdown,
	},
	data() {
		return {
			currentGreeting: '',
			isDarkMode: false,
			isDropdownOpen: false,
		}
	},
	created() {
		this.updateGreeting()
		this.loadTheme()
	},
	methods: {
		updateGreeting() {
			const today = new Date()
			const curHour = today.getHours()

			if (curHour < 12) {
				this.currentGreeting = 'morning'
			} else if (curHour < 18) {
				this.currentGreeting = 'afternoon'
			} else {
				this.currentGreeting = 'evening'
			}
		},

		loadTheme() {
			const savedTheme = localStorage.getItem('color-theme')

			if (savedTheme === 'dark') {
				this.isDarkMode = true
				document.documentElement.classList.add('dark')
			} else if (savedTheme === 'light') {
				this.isDarkMode = false
				document.documentElement.classList.add('light')
			} else {
				this.isDarkMode = true
				localStorage.setItem('color-theme', 'dark')
				document.documentElement.classList.add('dark')
			}
		},
		toggleTheme() {
			this.isDarkMode = !this.isDarkMode
			if (this.isDarkMode) {
				document.documentElement.classList.add('dark')
				localStorage.setItem('color-theme', 'dark')
			} else {
				document.documentElement.classList.remove('dark')
				localStorage.setItem('color-theme', 'light')
			}
		},
	},
}


</script>
